require('../../node_modules/uikit/dist/js/uikit.min.js');
import UIkit from 'uikit';

$(document).on('click', ".mobile-menu-icon", function ( event ) {
    $('.mobile-menu-icon label').removeClass('return');
    $('.mobile-menu-overlay').addClass('menu-fixed');
    $('html').css('overflow-y','hidden');

    if($(this).find('input').is(':checked')) {
        $('.mobile-menu-block').addClass('open');
        $('body').addClass('filter-open');
    } else {
        $('.mobile-menu-block').removeClass('open');
        $('body').removeClass('filter-open');
    }
});

$(document).on('click', ".mobile-menu-overlay.menu-fixed", function ( event ) {
    $('.mobile-menu-overlay').removeClass('menu-fixed');
    $('html').css('overflow-y','visible');
    $('.mobile-menu-block').removeClass('open');

    if($('.mobile-menu-icon').find('input').is(':checked')) {
        $('.mobile-menu-icon label').addClass('return');
        $('.mobile-menu-icon input').prop('checked', false);
        $('body').removeClass('filter-open');
    } else {
        $('.mobile-menu-icon label').removeClass('return');
        $('.mobile-menu-icon input').prop('checked', true);
        $('body').removeClass('filter-open');
    }
});

$(document).on('click', ".mobile-menu-block.open .close-menu", function ( event ) {
    $('.mobile-menu-overlay').removeClass('menu-fixed');
    $('html').css('overflow-y','visible');

    if($('.mobile-menu-icon').find('input').is(':checked')) {
        $('.mobile-menu-icon label').addClass('return');
        $('.mobile-menu-icon input').prop('checked', false);
        $('body').removeClass('filter-open');
    } else {
        $('.mobile-menu-icon label').removeClass('return');
        $('.mobile-menu-icon input').prop('checked', true);
        $('body').removeClass('filter-open');
    }
});

UIkit.scrollspy('.scroll-fade-fast', {cls:'uk-animation-fade', repeat: true, delay: 100});
UIkit.scrollspy('.scroll-fade-slow', {cls:'uk-animation-fade', repeat: true, delay: 200});
UIkit.scrollspy('.scroll-fade-1', {cls:'uk-animation-fade', repeat: true, delay: 200});
UIkit.scrollspy('.scroll-fade-2', {cls:'uk-animation-fade', repeat: true, delay: 400});
UIkit.scrollspy('.scroll-fade-3', {cls:'uk-animation-fade', repeat: true, delay: 600});
UIkit.scrollspy('.scroll-left-small', {cls:'uk-animation-slide-left-small', repeat: true, delay: 100});
UIkit.scrollspy('.scroll-right-small', {cls:'uk-animation-slide-right-small', repeat: true, delay: 100});
UIkit.scrollspy('.scroll-left-medium', {cls:'uk-animation-slide-left-medium', repeat: true, delay: 200});
UIkit.scrollspy('.scroll-right-medium', {cls:'uk-animation-slide-right-medium', repeat: true, delay: 200});
UIkit.scrollspy('.scroll-left', {cls:'uk-animation-slide-left', repeat: true, delay: 250});
UIkit.scrollspy('.scroll-right', {cls:'uk-animation-slide-right', repeat: true, delay: 250});
UIkit.scrollspy('.scroll-left-large', {cls:'uk-animation-slide-left', repeat: true, delay: 350});
UIkit.scrollspy('.scroll-right-large', {cls:'uk-animation-slide-right', repeat: true, delay: 350});

UIkit.scrollspy('.scroll-left-small-norepeat', {cls:'uk-animation-slide-left-small', repeat: false, delay: 100});
UIkit.scrollspy('.scroll-right-small-norepeat', {cls:'uk-animation-slide-right-small', repeat: false, delay: 100});
UIkit.scrollspy('.scroll-left-medium-norepeat', {cls:'uk-animation-slide-left-medium', repeat: false, delay: 150});
UIkit.scrollspy('.scroll-right-medium-norepeat', {cls:'uk-animation-slide-right-medium', repeat: false, delay: 150});
UIkit.scrollspy('.scroll-left-norepeat', {cls:'uk-animation-slide-left', repeat: false, delay: 200});
UIkit.scrollspy('.scroll-right-norepeat', {cls:'uk-animation-slide-right', repeat: false, delay: 200});
UIkit.scrollspy('.scroll-left-large-norepeat', {cls:'uk-animation-slide-left', repeat: false, delay: 220});
UIkit.scrollspy('.scroll-right-large-norepeat', {cls:'uk-animation-slide-right', repeat: false, delay: 220});


UIkit.scrollspy('.scroll-fade-fast-norepeat', {cls:'uk-animation-fade', repeat: false, delay: 100});

UIkit.scrollspy('.scroll-fade1-fast-norepeat', {cls:'uk-animation-fade', repeat: false, delay: 100});
UIkit.scrollspy('.scroll-fade2-fast-norepeat', {cls:'uk-animation-fade', repeat: false, delay: 200});
UIkit.scrollspy('.scroll-fade3-fast-norepeat', {cls:'uk-animation-fade', repeat: false, delay: 300});


UIkit.scrollspy('.scroll-fade1-norepeat', {cls:'uk-animation-fade', repeat: false, delay: 200});
UIkit.scrollspy('.scroll-fade2-norepeat', {cls:'uk-animation-fade', repeat: false, delay: 300});
UIkit.scrollspy('.scroll-fade3-norepeat', {cls:'uk-animation-fade', repeat: false, delay: 400});


UIkit.scrollspy('.slide-up-medium-norepeat1', {cls:'uk-animation-slide-bottom-medium', repeat: false, delay: 100});
UIkit.scrollspy('.slide-up-medium-norepeat2', {cls:'uk-animation-slide-bottom-medium', repeat: false, delay: 400});
UIkit.scrollspy('.slide-up-medium-norepeat3', {cls:'uk-animation-slide-bottom-medium', repeat: false, delay: 600});
UIkit.scrollspy('.slide-up-medium-norepeat4', {cls:'uk-animation-slide-bottom-medium', repeat: false, delay: 800});

UIkit.util.ready(() => {
    UIkit.lightbox('.blog-content #photoGallery #photoGalleryInner > .row');
});

$(document).ready(function () {

    var targetValue = 23;
    var targetValue2 = 7;
    var targetValue3 = 2;
    var duration = 2000;
    var duration2 = 2000;
    var duration3 = 2000;
    var interval = 10;
    var interval2 = 10;
    var interval3 = 10;
/*    var increment = (targetValue / (duration / interval));
    var increment2 = (targetValue2 / (duration2 / interval2));
    var increment3 = (targetValue3 / (duration3 / interval3));*/
    var increment = 1;
    var increment2 = 7 / 23;
    var increment3 = 2 / 23;
    var currentValue = 0;
    var currentValue2 = 0;
    var currentValue3 = 0;

    if ($('.li-top1').length > 0) {
        function updateCounter() {
            if (currentValue < targetValue) {
                currentValue += increment;
                $('.li-top1').text(currentValue.toFixed(0) + ' jaar');
            }
        }
    }

    if ($('.li-top2').length > 0) {
        function updateCounter2() {
            if (currentValue2 < targetValue2) {
                currentValue2 += increment2;
                $('.li-top2').text(currentValue2.toFixed(0) + ' jaar');
            }
        }
    }

    if ($('.li-top3').length > 0) {
        function updateCounter3() {
            if (currentValue3 < targetValue3) {
                currentValue3 += increment3;
                $('.li-top3').text(currentValue3.toFixed(0) + ' jaar');
            }
        }
    }

/*    var interval = setInterval(updateCounter, 50);

    if (currentValue >= targetValue) {
        clearInterval(interval);
    }*/

    function startCounter(entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                var interval = setInterval(updateCounter, 100);
                var interval2 = setInterval(updateCounter2, 100);
                var interval3 = setInterval(updateCounter3, 100);

                if (currentValue >= targetValue) {
                    clearInterval(interval);
                }
                if (currentValue2 >= targetValue2) {
                    clearInterval(interval2);
                }
                if (currentValue3 >= targetValue3) {
                    clearInterval(interval3);
                }

                observer.unobserve(entry.target);
            }
        });
    }

    var observer = new IntersectionObserver(startCounter, {
        root: null,
        rootMargin: '0px',
        threshold: 0.5
    });

    if ($('.li-top1').length > 0) {
        observer.observe(document.querySelector('.li-top1'));
    }
    if ($('.li-top2').length > 0) {
        observer.observe(document.querySelector('.li-top2'));
    }
    if ($('.li-top3').length > 0) {
        observer.observe(document.querySelector('.li-top3'));
    }

/*    function updateCounter() {
        var currentCount = parseInt($('.li-top1').text());

        if (currentCount < 25) {
            $('.li-top1').text(currentCount + 1 + '%');
        }
    }

    setInterval(updateCounter, 1000);*/
});
